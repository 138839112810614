<script setup>
import { ref, inject } from 'vue';

const $require = inject('$require');
let pageData = ref(null);
let pageNum = ref(1);
let pageSize = ref(10);
let total = ref(0);
let loading = ref(false);
let finished = ref(false);
/***
 *
 * 页面数据
 */
let getList = () => {
    $require.HttpPost('/h5/payment/getPayPlan', {
        page_num: pageNum.value,
        page_size: pageSize.value,

    })
        .then((res) => {
            pageData.value = res.data;
            total.value = res.data.total;
        });
};
getList();
/**
 * 上拉加载
 */
let onLoad = () => {
    loading.value = false;
    // console.log(arr.length)
    if (pageNum.value >= Math.ceil(total.value / pageSize.value)) {
        finished.value = true;
    } else {
        pageNum.value++;
        getList();
    }
};
</script>
<template>
    <div class="dailyFeeDetail">
        <div class="syyj">
            <div class="title">剩余应缴金额:</div>
            <!-- <div class="money"><span>￥</span>{{pageData && pageData.keyPayPriceSum }}</div> -->
            <div class="money" v-if="pageData"><span class="font32">￥</span>{{pageData.keyPayPriceSum}}</div>
            <div class="money" v-else><span class="font32">￥</span>0</div>
        </div>
        <van-list
            v-model:loading="loading"
            :finished="finished"
            :offset="30"
            finished-text="没有更多了"
            @load="onLoad"
        >
        <template v-if="pageData && pageData.list">
            <div class="pubBox" v-for="(item, index) in pageData.list" :key="index">
                <div class="list fontBold font32 listAfter">合同编号：{{ item.contract_no }}</div>
                <div class="pubTable">
                    <div class="tableTitle">
                        <div>缴费时间</div>
                        <div>应缴金额</div>
                        <div>已缴金额</div>
                        <div>待缴金额</div>
                    </div>
                    <div class="tableList" v-for="(ite, ind) in item.price_list" :key="ind">
                        <div>{{ ite.confirm_time }}</div>
                        <div>{{ ite.key_total_price }}</div>
                        <div v-if="ite.key_update_price <=0">￥{{ ite.key_update_price }}</div>
                        <div v-else style="color:#529100">￥{{ ite.key_update_price }}</div>
                        <!-- <div>￥{{ ite.key_total_price }}</div> -->
                         <div>{{
                ite.key_pay_price
              }}</div>
                    </div>
                </div>
            </div>
            </template>
        </van-list>
    </div>
</template>

<style scoped lang="scss">
.dailyFeeDetail {
    padding: 25px;
    box-sizing: border-box;
    min-height: 100vh;
    background-color: #f9f9f9;
}
.syyj {
    width: 100%;
    height: 190px;
    background: #ffffff;
    border-radius: 5px 5px 5px 5px;
    opacity: 1;
    margin-bottom: 10px;
    padding: 28px;
    box-sizing: border-box;
    .title {
        width: 252px;
        font-size: 36px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.3);
        line-height: 21px;
    }
    .money {
        font-size: 60px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #000000;
        line-height: 48px;
        width: 100%;
        text-align: right;
        // padding-left: 252px;
        box-sizing: border-box;
        margin-top: 12px;
        height: 100px;
        line-height: 100px;
    }
}
</style>
